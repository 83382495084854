@charset "UTF-8";
/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.tenant-yww .c-list-filter {
  margin-bottom: 30px;
}
.tenant-yww .c-list-filter.filter-expanded {
  background-color: #f8f8f8;
}
.tenant-yww .c-list-filter.no-hide-show {
  padding: 0;
}
.tenant-yww .c-list-filter .filter-list, .tenant-yww .c-list-filter .show-filter {
  width: 95.2380952381%;
  max-width: 730.4761904762px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .filter-list, .tenant-yww .c-list-filter .show-filter {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .filter-list, .tenant-yww .c-list-filter .show-filter {
    width: 48.1818181818%;
    max-width: 658.1636363636px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.tenant-yww .c-list-filter .show-filter {
  margin-bottom: 30px;
  margin-top: -15px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .show-filter {
    margin-top: -20px;
  }
}
.tenant-yww .c-list-filter .show-filter.show {
  background-color: transparent;
}
.tenant-yww .c-list-filter .show-filter.show button:after {
  content: "–";
  line-height: 17px;
}
.tenant-yww .c-list-filter .show-filter button {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding: 15px 0 0;
  color: #0073cd;
  border-bottom: solid 2px #0073cd;
  border-radius: 0;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .show-filter button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .show-filter button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .show-filter button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .show-filter button {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .show-filter button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .show-filter button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .show-filter button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .show-filter button {
    padding: 0;
    margin-top: 20px;
  }
}
.tenant-yww .c-list-filter .show-filter button:after {
  content: "+";
  position: absolute;
  font-size: 18px;
  margin-left: 5px;
  color: #0073cd;
}
.tenant-yww .c-list-filter .filter-icon {
  display: none;
}
.tenant-yww .c-list-filter .slider-container {
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .slider-container {
    border-bottom: none;
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}
.tenant-yww .c-list-filter .filter-list {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-yww .c-list-filter .filter-list {
    overflow: hidden;
  }
}
.tenant-yww .c-list-filter .filter-list .slider-tooltip {
  vertical-align: text-top;
  margin-left: 5px;
}
.tenant-yww .c-list-filter .filter-list .slider-tooltip svg {
  width: 16px;
  height: 16px;
  margin-top: 6px;
}
.tenant-yww .c-list-filter .filter-list .slider-tooltip-body {
  width: 210px;
  height: auto;
  background-color: #ffffff;
  box-shadow: -2px 2px 10px 0 rgba(215, 215, 215, 0.5);
  border: none;
  padding: 15px;
  cursor: default;
  top: 32px;
}
.tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-size: 10px;
  font-size: 0.625rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
    font-size: 10px;
    font-size: 0.625rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
    font-size: 10px;
    font-size: 0.625rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
    font-size: 10px;
    font-size: 0.625rem;
  }
}
.tenant-yww .c-list-filter .filter-list .facet-list {
  margin: 0 10px 10px;
}
.tenant-yww .c-list-filter .filter-list .facet-list label {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ffffff;
  color: #0073cd;
  border: 2px solid #ffffff;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .filter-list .facet-list label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .filter-list .facet-list label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .filter-list .facet-list label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .filter-list .facet-list label:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .filter-list .facet-list label:hover, .tenant-yww .c-list-filter .filter-list .facet-list label:focus {
    background-color: #404040;
    color: #ffffff;
    border: 2px solid #ffffff;
  }
}
.tenant-yww .c-list-filter .filter-list .facet-list.active label {
  background-color: #404040;
  color: #ffffff;
}
.tenant-yww .c-list-filter .slider-label {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 7px;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .slider-label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .slider-label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .slider-label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .slider-label {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .slider-label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .slider-label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .slider-label {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .form-input-range {
  -webkit-appearance: none;
}
.tenant-yww .c-list-filter .form-input-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: linear-gradient(to right, #b4d9ff, #38bfff);
  border-radius: 12px;
}
.tenant-yww .c-list-filter .form-input-range::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: linear-gradient(to right, #b4d9ff, #38bfff);
  border-radius: 12px;
}
.tenant-yww .c-list-filter .form-input-range::-moz-focus-inner {
  border: 0;
}
.tenant-yww .c-list-filter .form-input-range::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: linear-gradient(to right, #b4d9ff, #38bfff);
  border-radius: 12px;
  border-color: transparent;
  background: transparent;
  color: transparent;
}
.tenant-yww .c-list-filter .form-input-range::-ms-fill-lower {
  background: linear-gradient(to right, #b4d9ff, #38bfff);
}
.tenant-yww .c-list-filter .form-input-range::-ms-fill-upper {
  background: linear-gradient(to right, #b4d9ff, #38bfff);
}
.tenant-yww .c-list-filter .form-input-range::-webkit-slider-thumb {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px 1px;
  cursor: pointer;
  margin-top: -14px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}
.tenant-yww .c-list-filter .form-input-range::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px 1px;
  cursor: pointer;
}
.tenant-yww .c-list-filter .form-input-range::-ms-thumb {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px 1px;
  cursor: pointer;
}
.tenant-yww .c-list-filter .tickList {
  margin-top: 0;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .tickList {
    margin-top: 20px;
    overflow: visible;
  }
}
.tenant-yww .c-list-filter .tickList li.ticks {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  vertical-align: text-top;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .tickList li.ticks {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .tickList li.ticks {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .tickList li.ticks {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .tickList li.ticks {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .tickList li.ticks {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .tickList li.ticks {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .tickList li.ticks {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .tickList li.ticks.active {
  font-weight: bold;
}
.tenant-yww .c-list-filter .tickList li.ticks::before {
  width: 6px;
  height: 6px;
  background-color: #e0e0e0;
  top: 8px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .tickList li.ticks::before {
    top: -12px;
  }
}
.tenant-yww .c-list-filter .range-tooltip {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  border: none;
  height: auto;
  padding-top: 0px;
  margin-top: 0;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .range-tooltip {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .range-tooltip {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .range-tooltip {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .range-tooltip::before {
  display: none;
}
.tenant-yww .c-list-filter .facet-container {
  margin-bottom: 10px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .facet-container {
    margin-bottom: 20px;
  }
}
.tenant-yww .c-list-filter .c-facet-filter {
  margin-bottom: 0;
}
.tenant-yww .c-list-filter .facet-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 10px;
  display: block;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .facet-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .facet-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .facet-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .facet-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .facet-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .facet-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .facet-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .facets {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.tenant-yww .c-list-filter .date-filter-wrapper {
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
  margin-bottom: 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-wrapper {
    border-top: none;
    padding-top: 0;
    margin-bottom: 30px;
  }
}
.tenant-yww .c-list-filter .date-filter-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .date-filter-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .date-filter-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .date-filter-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .date-filter-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .date-filter-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .date-filter-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .date-filter-fields-wrapper {
  margin-bottom: 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-fields-wrapper {
    margin-bottom: 10px;
    display: inline-block;
  }
}
.tenant-yww .c-list-filter .date-filter-submit-button-wrapper {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-submit-button-wrapper {
    display: inline-block;
  }
}
.tenant-yww .c-list-filter .date-filter-field {
  display: block;
  margin: 0 0 20px;
  position: relative;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-field {
    display: inline-block;
    margin: 0 40px 0 0;
  }
}
.tenant-yww .c-list-filter .date-filter-field-label {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-field-label {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .date-filter-field-label {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .date-filter-field-label {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .date-filter-error-msg {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #f20000;
  margin-bottom: 10px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-error-msg {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .date-filter-error-msg {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .date-filter-error-msg {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-error-msg {
    position: absolute;
    right: 0;
    left: 0;
  }
}
.tenant-yww .c-list-filter .date-filter-error-msg.error-msg-hide {
  display: none;
  background-color: #f20000;
}
.tenant-yww .c-list-filter .date-filter-submit-button {
  padding: 0;
  border-radius: 0;
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ffffff;
  color: #0073cd;
  border: 2px solid #ffffff;
  /* Medium devices (desktops, 1024px and up) */
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAAXNSR0IArs4c6QAAAM5JREFUGBljZAACR0fHqv///788cODAXBAfHXh6eop+//59Jjs7ezYzULE5UPFyoCJfBQWFJw8ePDiPrAGk+MePH/uBYnZ///5VYgYqeApU+AUo4I6uCaYYaKA2IyPjVU5OzhBGmGkODg7FQHYPEP8HSqZwcHBsBpkMUwzkO27fvv01XANII5qmZ0DF0iCTYYpBaphBBAwAnXdcUVHxG5DvBsR8QMWPgIqtQSbD1KBoAAkCNR1TUlLSADIVgaFitmPHjmcwxXjp0NBQTmwKANqhXYIGZpGRAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 13px top 15px;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .date-filter-submit-button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .date-filter-submit-button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .date-filter-submit-button {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .date-filter-submit-button:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .date-filter-submit-button:hover, .tenant-yww .c-list-filter .date-filter-submit-button:focus {
    background-color: #404040;
    color: #ffffff;
    border: 2px solid #ffffff;
  }
}
.tenant-yww .c-list-filter .date-filter-submit-button:hover {
  background-color: #404040;
  color: #ffffff;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAAAXNSR0IArs4c6QAAAG5JREFUGBmNjcERgCAMBHnxtjXf/myBfiyCNuzJuMcQB1FGbyaTcLkNISAzm6hZ80jaK1f2DJHaqYNa3yD5da9cdDDxkB4gngPap9tRGXLRBTKPAacJteBWD9C6Hxzw3oHfQANmpVF271cHWEbBE9vQoAchPSw/AAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 13px top 15px;
}
.tenant-yww .c-list-filter .date-filter-submit-button:focus {
  background-color: white;
  color: #0073cd;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAAXNSR0IArs4c6QAAAM5JREFUGBljZAACR0fHqv///788cODAXBAfHXh6eop+//59Jjs7ezYzULE5UPFyoCJfBQWFJw8ePDiPrAGk+MePH/uBYnZ///5VYgYqeApU+AUo4I6uCaYYaKA2IyPjVU5OzhBGmGkODg7FQHYPEP8HSqZwcHBsBpkMUwzkO27fvv01XANII5qmZ0DF0iCTYYpBaphBBAwAnXdcUVHxG5DvBsR8QMWPgIqtQSbD1KBoAAkCNR1TUlLSADIVgaFitmPHjmcwxXjp0NBQTmwKANqhXYIGZpGRAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 13px top 15px;
}
.tenant-yww .c-list-filter .react-datepicker {
  border: none;
  box-shadow: -2px 2px 10px 0 rgba(215, 215, 215, 0.5);
}
.tenant-yww .c-list-filter .react-datepicker-popper {
  z-index: 20;
}
.tenant-yww .c-list-filter .react-datepicker-popper[data-placement^=bottom] {
  margin-top: 15px;
}
.tenant-yww .c-list-filter .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  border-bottom-color: #ffffff;
}
.tenant-yww .c-list-filter .react-datepicker-popper .react-datepicker__triangle {
  left: 50%;
}
.tenant-yww .c-list-filter .react-datepicker__input-container input {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding: 10px 18px;
  border: solid 1px #e0e0e0;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__input-container input::-webkit-input-placeholder {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::-webkit-input-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::-webkit-input-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::-webkit-input-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__input-container input:-moz-placeholder {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input:-moz-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input:-moz-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input:-moz-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__input-container input::-moz-placeholder {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::-moz-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::-moz-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::-moz-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__input-container input:-ms-input-placeholder {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input:-ms-input-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input:-ms-input-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input:-ms-input-placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__input-container input::placeholder {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__input-container input::placeholder {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__header {
  background-color: #ffffff;
  padding: 0;
  border: none;
}
.tenant-yww .c-list-filter .react-datepicker__current-month {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__current-month {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__current-month {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__current-month {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .react-datepicker__current-month {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.54;
  letter-spacing: 0.7px;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__current-month {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__current-month {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__current-month {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__navigation--previous {
  content: "";
  border: 2px solid #404040;
  border-bottom-width: 0;
  border-left-width: 0;
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 5px);
  -webkit-transform: rotate(-135deg);
  -khtml-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  top: 17px;
  left: 15px;
}
.tenant-yww .c-list-filter .react-datepicker__navigation--previous, .tenant-yww .c-list-filter .react-datepicker__navigation--previous:hover {
  border-color: #404040;
}
.tenant-yww .c-list-filter .react-datepicker__navigation--next {
  content: "";
  border: 2px solid #404040;
  border-bottom-width: 0;
  border-left-width: 0;
  position: absolute;
  width: 10px;
  height: 10px;
  top: calc(50% - 5px);
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 17px;
  right: 15px;
}
.tenant-yww .c-list-filter .react-datepicker__navigation--next, .tenant-yww .c-list-filter .react-datepicker__navigation--next:hover {
  border-color: #404040;
}
.tenant-yww .c-list-filter .react-datepicker__day-names {
  margin: 0 0 0 15px;
}
.tenant-yww .c-list-filter .react-datepicker__day-name {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin: 0 15px 15px 0;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__day-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__day-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__day-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .react-datepicker__day-name {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__day-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__day-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__day-name {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__month {
  margin: 0 0 0 15px;
}
.tenant-yww .c-list-filter .react-datepicker__day {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin: 0 15px 15px 0;
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .react-datepicker__day {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .react-datepicker__day {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .react-datepicker__day {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .react-datepicker__day {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.54;
  letter-spacing: 0.7px;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__day {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__day {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .react-datepicker__day {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.tenant-yww .c-list-filter .react-datepicker__day, .tenant-yww .c-list-filter .react-datepicker__day:hover {
  border-radius: 50%;
}
.tenant-yww .c-list-filter .react-datepicker__day--keyboard-selected, .tenant-yww .c-list-filter .react-datepicker__day--keyboard-selected:hover {
  background-color: #e0e0e0;
  color: #404040;
}
.tenant-yww .c-list-filter .react-datepicker__day--selected, .tenant-yww .c-list-filter .react-datepicker__day--selected:hover {
  background-color: #0073cd;
  width: 30px;
  height: 30px;
  padding-top: 4px;
}
.tenant-yww .c-list-filter .reset-btn {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
  padding: 0;
  margin: 0 auto 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .reset-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-list-filter .reset-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-list-filter .reset-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .reset-btn {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.54;
  letter-spacing: 0.7px;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .reset-btn {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .reset-btn {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .reset-btn {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-list-filter .reset-btn {
    margin-bottom: 30px;
  }
}
.tenant-yww .c-list-filter .reset-icon {
  display: inline-block;
  margin-left: 4px;
}
.tenant-yww .c-list-filter .reset-icon svg {
  width: 7px;
  height: 10px;
}
.tenant-yww .c-tabs-filter-listing .description, .tenant-yww .c-tabs-filter-listing .title.heading-2, .tenant-yww .c-tabs-filter-listing .sub-title {
  width: 95.2380952381%;
  max-width: 730.4761904762px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-tabs-filter-listing .description, .tenant-yww .c-tabs-filter-listing .title.heading-2, .tenant-yww .c-tabs-filter-listing .sub-title {
    width: 63.9784946237%;
    max-width: 655.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .description, .tenant-yww .c-tabs-filter-listing .title.heading-2, .tenant-yww .c-tabs-filter-listing .sub-title {
    width: 64.8484848485%;
    max-width: 885.8303030303px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-tabs-filter-listing .description, .tenant-yww .c-tabs-filter-listing .title.heading-2, .tenant-yww .c-tabs-filter-listing .sub-title {
    width: 48.1818181818%;
    max-width: 658.1636363636px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.tenant-yww .c-tabs-filter-listing .w--content {
  max-width: 100%;
}
.tenant-yww .c-tabs-filter-listing .sub-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 5px;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-tabs-filter-listing .sub-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .sub-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-tabs-filter-listing .sub-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .sub-title {
    margin-bottom: 10px;
  }
}
.tenant-yww .c-tabs-filter-listing .title.heading-2 {
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: 0;
  line-height: 1.27;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.16;
  }
}
.tenant-yww .c-tabs-filter-listing .description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 45px;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-tabs-filter-listing .description {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .description {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-tabs-filter-listing .description {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-yww .c-tabs-filter-listing .description {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0 20px;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-tabs-filter-listing .description {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .description {
    margin-bottom: 55px;
  }
}
.tenant-yww .c-tabs-filter-listing .description a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: left;
  color: #0073cd;
  cursor: pointer;
  text-decoration: underline;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-tabs-filter-listing .description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-tabs-filter-listing .description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .description a:hover, .tenant-yww .c-tabs-filter-listing .description a:focus {
    color: #0073cd;
    text-decoration: none;
  }
}
.tenant-yww .c-tabs-filter-listing .description a.disabled, .tenant-yww .c-tabs-filter-listing .description a[disabled], fieldset[disabled] .tenant-yww .c-tabs-filter-listing .description a {
  text-align: left;
  color: #a4a4a4;
}
.tenant-yww .c-tabs-filter-listing .total-count {
  margin-bottom: 27px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-tabs-filter-listing .total-count {
    margin-bottom: 54px;
  }
}
.tenant-yww .c-tabs-filter-listing .component.c-tab-feature-list .w--top {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tenant-yww .range-container .track {
    background: linear-gradient(to right, #b4d9ff, #38bfff);
  }
}

.lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip {
  margin-right: 5px;
  margin-left: 0;
}
.lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-body {
  left: 0;
}
.lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-body:after {
  left: 17px;
  right: auto;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-body:after {
    left: 7px;
    right: auto;
  }
}
.lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.5;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  font-size: 10px;
  font-size: 0.625rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
    font-size: 10px;
    font-size: 0.625rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
    font-size: 10px;
    font-size: 0.625rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-list-filter .filter-list .slider-tooltip-placeholder-description {
    font-size: 10px;
    font-size: 0.625rem;
    line-height: 1.5;
  }
}
.lang-ar .tenant-yww .c-list-filter .tickList li.ticks {
  transform: rotateY(180deg);
}
.lang-ar .tenant-yww .c-list-filter .tickList li.ticks.active {
  font-weight: bold;
}
.lang-ar .tenant-yww .c-list-filter .slider-container .range-container {
  direction: ltr;
  transform: rotateY(180deg);
}
.lang-ar .tenant-yww .c-list-filter .range-tooltip {
  transform: rotateY(180deg);
}
.lang-ar .tenant-yww .c-list-filter .reset-icon {
  margin-right: 4px;
  margin-left: 0;
}
.lang-ar .tenant-yww .c-tabs-filter-listing .sub-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .sub-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .sub-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .sub-title {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .tenant-yww .c-tabs-filter-listing .title.heading-2 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.42;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.28;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 46px;
    font-size: 2.875rem;
    line-height: 1.26;
  }
}
.lang-ar .tenant-yww .c-tabs-filter-listing .description {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.85;
  letter-spacing: 0.7px;
  font-size: 13px;
  font-size: 0.8125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .description {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .description {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.63;
    letter-spacing: 0.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .tenant-yww .c-tabs-filter-listing .description {
    font-size: 16px;
    font-size: 1rem;
  }
}

.lang-ru .tenant-yww .c-tabs-filter-listing .title.heading-2 {
  font-family: "robotobold", "Open Sans", Arial, sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: 0;
  line-height: 1.12;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ru .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ru .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ru .tenant-yww .c-tabs-filter-listing .title.heading-2 {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.2;
  }
}